var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"js-copy js-content pd_body"},[_c('div',{staticClass:"hk_content"},[_c('topHeader'),(_vm.SpecialDetails == null || _vm.SpecialDetails.subordinate == null)?_c('div',{staticStyle:{"height":"100vh"}}):_vm._e(),_vm._m(0),_c('div',{staticClass:"Main margin_auto flex justify_content_sb"},[_c('div',{staticClass:"hk_list"},[(
						_vm.SpecialDetails.subordinate &&
						_vm.SpecialDetails.subordinate.length > 0
					)?_c('div',{staticClass:"hk_header"},[_c('div',{staticClass:"hk_header_top"},[_c('div',{staticClass:"hk_header_title",on:{"click":function($event){return _vm.hk_header_item_click(null, -1)}}},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/hk/hk_zdxx_1.png"}})]),_c('div',{staticClass:"hk_header_line"}),_vm._l((_vm.SpecialDetails.subordinate),function(item,index){return _c('div',{key:index,staticClass:"hk_header_item pointer hovercolor",class:{ hk_header_item_sel: _vm.hk_header_item_index == index },on:{"click":function($event){return _vm.hk_header_item_click(item, index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2),_vm._m(1)]):_vm._e(),_c('div',_vm._l((_vm.hk_header_item_index == -1
							? _vm.allArray
							: _vm.SpecialDetails.subordinate[_vm.hk_header_item_index].data),function(items,indexs){return _c('div',{key:indexs,staticClass:"week_pc align_items_c pointer",on:{"click":function($event){return _vm.$router.push({
								path: items.type == 2 ? '/kuaixun_wz' : '/gw_detail',
								query: { id: items.id },
							})}}},[(indexs < (_vm.hk_header_item_index == 0 ? 100 : 20))?_c('wzitemhk',{staticClass:"wzitemnew",attrs:{"item":items}}):_vm._e(),(false)?_c('div',{staticClass:"hk_center_banner"},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/hk/hk_center_banner.png"}})]):_vm._e()],1)}),0)]),_c('div',{staticClass:"MainRight"},[_c('div',{staticClass:"hk_right_space1"}),_c('hkFlash',{attrs:{"selarticleList":_vm.hkFlashLists}}),_c('div',{staticClass:"hk_right_space"}),_c('hkActivity',{attrs:{"selarticleList":this.SpecialDetails.activityList}}),_c('div',{staticClass:"hk_right_space"}),_c('hkPeople'),_vm._m(2)],1)]),_c('bottom'),_c('div',{staticStyle:{"height":"30px"}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dexBox_week"},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/hk/banner.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hk_header_bottom"},[_c('div',{staticClass:"hk_header_title_bl1"}),_c('div',{staticClass:"hk_header_title_bl2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hk_metaera pointer"},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/hk/hk_metaera.png"}})])
}]

export { render, staticRenderFns }