<template>
	<div class="hk_eventWraper" v-if="selarticleList.length > 0">
		<div class="hk_flash_top">
			<div class="flex align_items_c justify_content_sb">
				<div class="hk_title">
					<img
						src="https://zy.metaera.media/assets/images/hk/hk_activity_1.png"
					/>
				</div>
				<div
					class="flex align_items_c pointer hk_title_more hk_pointer"
					@click="$router.push({ path: '/events' })"
				>
					More
				</div>
			</div>
			<div class="hk_flash_bottom">
				<div class="hk_flash_title_bl1"> </div>
				<div class="hk_flash_title_bl2"> </div>
			</div>
		</div>
		<div class="hk_activity_list">
			<div
				class="hk_activity_item pointer"
				v-for="(item, index) in selarticleList"
				:key="index"
			>
				<div class="hk_activity_item_left">
					<img
						class="hk_activity_item_img pointer"
						v-lazy="item.pic"
						:key="item.pic"
						alt=""
					/>
				</div>
				<div class="hk_activity_item_right">
					<div>
						<div class="hk_activity_item_start_hour">
							{{
								item.mainevent ? item.mainevent : formatTime(item.start_time)
							}}
						</div>
					</div>
					<div class="hk_activity_item_title hovercolor pointer">
						{{ item.title }}
					</div>
					<div class="hk_activity_item_address"> 香港 </div>
					<div class="hk_activity_item_date">
						{{ formatDate(item.start_time) }}
						（{{ item.timeZone }}）
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import clipboard from "clipboard";
import { eventBus } from "../../components/pc/leftTab";
export default {
	props: ["selarticleList"],
	data() {
		return {
			currentShow: 0,
			localLanguage: 1,
		};
	},
	mounted() {
		eventBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	methods: {
		formatDate(dateString) {
			const date = new Date(dateString);
			return date.toLocaleString("zh-CN", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				hour12: false,
			});
		},
		formatTime(timestamp) {
			const date = new Date(timestamp);
			let hours = date.getHours();
			const minutes = date.getMinutes();
			const ampm = hours >= 12 ? "PM" : "AM";
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
			const strMinutes = minutes < 10 ? "0" + minutes : minutes;
			return hours + ":" + strMinutes + " " + ampm;
		},
		changePart(ind) {
			this.currentShow = ind;
		},
	},
};
</script>

<!-- hk_flash_list -->
<style scoped>
.hk_activity_list {
	margin-top: 20px;
	opacity: 1;
	box-sizing: border-box;
}

.hk_activity_item {
	margin-bottom: 30px;
	border-radius: 10px;
	display: flex;
	opacity: 1;
	background: #121212;
	box-sizing: border-box;
	border: 4px solid #0e0e0e;
	box-shadow: inset 0px 0px 23px 0px rgba(0, 0, 0, 0.6);
}
.hk_activity_item_left {
	/* align-content: center; */
}
.hk_activity_item_img {
	margin-left: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
	margin-right: 10px;
	border-radius: 10px;
	object-fit: cover;
	height: clamp(80px, calc(100vw * 80 / 1280), 140px);
	aspect-ratio: 16 / 9;
}

.hk_activity_item_right {
	margin-left: 10px;
	position: relative;
	align-content: baseline;
	padding-top: 10px;
	padding-bottom: 10px;

	/* display: flex; */
	/* flex-direction: column; */
	/* justify-content: space-between; */
}
.hk_activity_item_start_hour {
	margin-top: 10px;
	border-radius: 4px;
	display: inline-block;
	padding-left: 4px;
	padding-right: 4px;
	padding-top: 2px;
	padding-bottom: 2px;
	opacity: 1;
	box-sizing: border-box;
	border: 2px solid #ffffff;
	opacity: 1;
	color: #ffffff;
	font-size: 12px;
	display: none;
}
.hk_activity_item_title {
	/* margin-top: 10px; */
	margin-right: 4px;
	font-size: 14px;
	font-weight: bold;
	line-height: 20px;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
.hk_activity_item_address {
	margin-top: 10px;
	font-size: 12px;
	font-weight: normal;
	line-height: 12px;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	display: none;
}
.hk_activity_item_date {
	display: flex;
	/* margin-top: 10px; */
	margin-bottom: 10px;
	font-size: 12px;
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}
</style>

<style scoped>
@media screen and (max-width: 1600px) {
	.hk_activity_item_date {
		display: none;
	}
	.hk_activity_item_right {
		align-content: center;
	}
}
@media screen and (max-width: 1200px) {
	.hk_activity_item_right {
		display: none;
	}
	.hk_activity_item_img {
		margin-left: 0px;
		margin-top: 0px;
		margin-bottom: 0px;
		border-radius: 10px;
		height: clamp(100px, calc(120vw * 100 / 414), 140px);
	}
}

@media screen and (max-width: 900px) {
	.hk_activity_item_right {
		display: block;
		align-content: baseline;
	}
	.hk_activity_item_date {
		display: flex;
	}
	.hk_activity_item_img {
		margin-left: 10px;
		margin-top: 10px;
		margin-bottom: 10px;
		border-radius: 10px;
		height: clamp(100px, calc(120vw * 100 / 414), 140px);
	}
}

@media screen and (max-width: 500px) {
	.hk_activity_item_right {
		display: block;
		align-content: center;
	}
	.hk_activity_item_date {
		display: none;
	}
	.hk_activity_item_img {
		margin-left: 10px;
		margin-top: 10px;
		margin-bottom: 10px;
		border-radius: 10px;
		height: clamp(100px, calc(120vw * 100 / 414), 140px);
	}
}
</style>
