<template>
	<div class="js-copy js-content pd_body">
		<div class="hk_content">
			<topHeader> </topHeader>
			<div
				style="height: 100vh"
				v-if="SpecialDetails == null || SpecialDetails.subordinate == null"
			>
			</div>
			<div class="dexBox_week">
				<img src="https://zy.metaera.media/assets/images/hk/banner.png" />
			</div>
			<div class="Main margin_auto flex justify_content_sb">
				<div class="hk_list">
					<div
						class="hk_header"
						v-if="
							SpecialDetails.subordinate &&
							SpecialDetails.subordinate.length > 0
						"
					>
						<div class="hk_header_top">
							<div
								class="hk_header_title"
								@click="hk_header_item_click(null, -1)"
							>
								<img
									src="https://zy.metaera.media/assets/images/hk/hk_zdxx_1.png"
								/>
							</div>
							<div class="hk_header_line"> </div>
							<div
								class="hk_header_item pointer hovercolor"
								v-for="(item, index) in SpecialDetails.subordinate"
								:key="index"
								:class="{ hk_header_item_sel: hk_header_item_index == index }"
								@click="hk_header_item_click(item, index)"
							>
								{{ item.name }}
							</div>
						</div>
						<div class="hk_header_bottom">
							<div class="hk_header_title_bl1"> </div>
							<div class="hk_header_title_bl2"> </div>
						</div>
					</div>

					<div>
						<div
							class="week_pc align_items_c pointer"
							v-for="(items, indexs) in hk_header_item_index == -1
								? allArray
								: SpecialDetails.subordinate[hk_header_item_index].data"
							:key="indexs"
							@click="
								$router.push({
									path: items.type == 2 ? '/kuaixun_wz' : '/gw_detail',
									query: { id: items.id },
								})
							"
						>
							<wzitemhk
								class="wzitemnew"
								:item="items"
								v-if="indexs < (hk_header_item_index == 0 ? 100 : 20)"
							>
							</wzitemhk>
							<div class="hk_center_banner" v-if="false">
								<img
									src="https://zy.metaera.media/assets/images/hk/hk_center_banner.png"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="MainRight">
					<div class="hk_right_space1"></div>
					<hkFlash :selarticleList="hkFlashLists"> </hkFlash>
					<div class="hk_right_space"></div>
					<hkActivity :selarticleList="this.SpecialDetails.activityList">
					</hkActivity>
					<div class="hk_right_space"></div>
					<hkPeople> </hkPeople>
					<div class="hk_metaera pointer">
						<img
							src="https://zy.metaera.media/assets/images/hk/hk_metaera.png"
						/>
					</div>
				</div>
			</div>
			<bottom></bottom>
			<div style="height: 30px"></div>
		</div>
	</div>
</template>

<script>
import { getSpecialDetails } from "@/api/home";
import wzitem from "./../../components/pc/wzitem.vue";
import download from "./../../components/pc/download.vue";
import topHeader from "../../components/pc/topHeader.vue";
import wzitemhk from "./../../components/pc/wzitemhk.vue";
import bottom from "../../components/pc/bottom.vue";
import hotArticle from "../home/hotArticle.vue";
import hkFlash from "./hkFlash.vue";
import hkActivity from "./hkActivity.vue";
import hkPeople from "./hkPeople.vue";

export default {
	name: "",
	components: {
		wzitem,
		download,
		topHeader,
		wzitemhk,
		bottom,
		hotArticle,
		hkFlash,
		hkActivity,
		hkPeople,
	},
	data() {
		return {
			active: 0,
			showMore: false,
			hk_header_item_index: -1,
			info: {},
			SpecialDetails: {},
			hkFlashLists: [],
			allArray: [],
		};
	},
	created() {
		this.getSpecialDetails();
	},
	methods: {
		hk_header_item_click(item, index) {
			this.hk_header_item_index = index;
		},

		getSpecialDetails() {
			let res = JSON.parse(sessionStorage.getItem("hkGetSpecialDetails"));
			if (res != null && res.code == 200) {
				this.reloadData(res);
			}
			getSpecialDetails({ id: 19 })
				.then((res) => {
					sessionStorage.setItem("hkGetSpecialDetails", JSON.stringify(res));
					this.reloadData(res);
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},

		reloadData(res) {
			if (res.code == 200) {
				res.data.subordinate.forEach((element) => {
					if (element.id == 21) {
						this.hkFlashLists = element.data.slice(0, 13);
					}
				});

				const subordinate = res.data.subordinate.filter(
					(item) => item.id !== 21 && item.id !== 22 && item.data.length > 0
				);

				const subordinate1 = res.data.subordinate.filter(
					(item) => item.id == 22
				);

				let tmpArray = subordinate.concat(subordinate1);
				let allArray = [];
				tmpArray.forEach((element) => {
					if (element.data.length > 0) {
						allArray.push(...element.data);
					}
				});

				allArray = allArray.slice().sort((a, b) => {
					const dateA = new Date(a.release_time);
					const dateB = new Date(b.release_time);
					return dateB - dateA; // 倒序排序
				});

				this.allArray = allArray;

				res.data.subordinate = tmpArray;
				this.SpecialDetails = res.data;
			}
		},
		md(item, index) {
			this.active = index;
			this.$el
				.querySelector(".id" + item.id)
				.scrollIntoView({ block: "start", behavior: "smooth" });
		},
	},
};
</script>
<style scoped>
.pd_body {
	height: 100vh;
	overflow-y: scroll;
	background: #000000;
}
.scroll-container {
	height: 100vh;
	overflow-y: scroll;
}
.js-copy {
	position: absolute;
	top: 0px;
	left: 0;
	width: calc(100vw);
	margin: 0 auto;
	/* background: black; */
}
.hk_bg_top {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(100vw * (3242 / 2684));
	z-index: 1; /* 背景图片在最底层 */
}
.hk_bg_right {
	position: absolute;
	top: 40vw;
	left: 0;
	width: 100%;
	height: calc(100vw * (3494 / 2238));
	z-index: 1; /* 背景图片在最底层 */
}
.hk_bg_left {
	position: absolute;
	top: 120vw;
	left: 0;
	width: 100%;
	height: auto;
	z-index: 1; /* 背景图片在最底层 */
	display: none;
}

.hk_bg_top img,
.hk_bg_right img,
.hk_bg_left img {
	width: 100%;
	height: auto;
	object-fit: cover; /* 使图片覆盖整个容器 */
}

.hk_content {
	position: relative;
	z-index: 2;
	min-height: 100vh;
	box-sizing: border-box;
	background-position: center calc(120%); /* 背景图片居中且向下偏移100px */
	background-repeat: no-repeat; /* 不重复背景图片 */
	background-size: contain; /* 调整图片大小以适应容器 */
}

.dexBox_week img {
	margin-top: 20px;
	width: 80%;
	margin-left: 10%;
	object-fit: cover;
	border-radius: 20px;
}
.dexBox_week {
	width: 100%;
	margin-top: 100px;
}
.wzitemnew {
	display: flex;
}
@media screen and (max-width: 900px) {
	.dexBox_week {
		margin-top: 60px;
	}
	.dexBox_week img {
		margin-top: 20px;
		width: 90%;
		margin-left: 5%;
		border-radius: 20px;
	}
}
</style>
<style scoped>
.Main {
	margin-left: 10%;
	width: 80%;
	padding-top: 10px;
	background: rgba(0, 0, 0, 0);
}
.MainRight {
	display: block;
	margin-left: 4%;
	width: 28%;
}
/* Main */

.hk_list {
	margin-left: 0px;
	width: 68%;
	padding-bottom: 20px;
	margin-top: 10px;
	margin-bottom: 20px;
}

.MainItem {
	width: 100%;
	opacity: 1;
	margin-top: 40px;
}

.MainItem:nth-child(1) {
	margin-top: 0;
}

.MainItemHeadNew div {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 30px;
	font-weight: 500;
	line-height: 30px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #1a1a1a;
}

.MainItemHeadNew {
	width: 100%;
	height: 61px;
	border-radius: 20px 20px 0 0;
	position: relative;
	margin-top: 20px;
}

.wzitem {
	width: 94%;
	margin: 0 auto;
	height: 142px;
	border-bottom: 1px solid #efefef;
}

.wzitem img {
	width: 160px;
	height: 100px;
	border-radius: 5px;
}

.wzitemL {
	width: 70%;
	margin-left: 21px;
	height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.wzitemL div:nth-child(1) {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.wzitemL div:nth-child(2) {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #a0a0a7;
}
.week_pc {
	display: contents;
}

.steps {
	width: 100px;
	margin-left: 10px;
}

.stepsTime {
	font-size: 12px;
	font-family: DINPro-Regular, DINPro;
	font-weight: 400;
	color: #000000;
}

.stepsVal {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000 !important;
	margin-top: 5px;
}

.js-box {
	margin-top: 0;
	position: fixed;
}
.hk_jxlist {
	background-color: #fff;
	border-radius: 20px;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 0px;
	padding-bottom: 20px;
	border: 1px solid #e1e3f1;
}
.hk_more {
	width: 100%;
	margin-top: 34px;
	padding-bottom: 20px;
	justify-content: center;
	display: flex;
}
.hk_more_btn {
	box-sizing: border-box;
	border: 2px solid #333333;
	border-radius: 20px;
	width: 92px;
	height: 32px;
	text-align: center;
}
@media screen and (max-width: 950px) {
	.Main {
		display: block;
		width: 90%;
		margin-left: 5%;
	}

	.MainRight {
		display: block;
		margin-left: 0%;
		width: 100%;
	}
	.Main_l {
		width: 100%;
		height: calc(100vw * 568 / 370);
	}
	.hk_list {
		width: 100%;
		margin-top: 20px;
		margin-left: 0px;
		margin-right: 0px;
		padding-bottom: 0px;
	}

	.MainItem {
		width: 90%;
		margin-left: 5%;
		/* background: #ffffff; */
		/* border-radius: 18px 18px 18px 18px; */
		opacity: 1;
		margin-top: 20px;
	}
	.hk_jxlist {
		padding-top: 10px;
		padding-bottom: 10px;
	}
}
</style>

<!-- hk_header -->
<style scoped>
.hk_header {
	margin-top: clamp(16px, calc(100vw * 20 / 1280), 30px);
}

.hk_header_top {
	display: flex;
	align-items: center;
}

.hk_header_bottom {
	margin-top: 10px;
}
</style>

<!-- hk_center_banner -->
<style scoped>
.hk_center_banner img {
	width: 100%;
	height: auto;
	object-fit: cover;
}

.hk_right_space1 {
	margin-top: clamp(32px, calc(100vw * 34 / 1280), 40px);
}
.hk_right_space {
	margin-top: 30px;
	margin-bottom: 50px;
	height: 1px;
	border-radius: 20px;
	opacity: 1;
	background: linear-gradient(
		90deg,
		#9945ff 0%,
		#8752f3 23%,
		#5497d5 44%,
		#43b4ca 55%,
		#28e0b9 67%,
		#19fb9b 93%
	);
	/* box-shadow: 0px 0px 15px 6px rgba(127, 106, 235, 0.5); */
}

.hk_metaera {
	margin-top: 50px;
}

.hk_metaera img {
	width: 100%;
	height: auto;
}
@media screen and (max-width: 900px) {
}
</style>
